@import "~react-image-gallery/styles/css/image-gallery.css";

body {
    color: #D0B76B;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    background-color: black;

}

div.section {
    width: 95vw;
    height: 95vh;
    overflow: hidden;
    padding: 2.5%;

}

div.header {
    display: flex;
    flex-flow: column nowrap;
    /* align-items: center; */
    justify-content: space-evenly;
}

div.header div  {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
div.header div img {
    max-width: 50vw;
    
}

div.header p {
    font-size: 5vw;
    width: 60vw;
    padding-left: 2%;
}

@media only screen and (max-width: 800px) {
    div.header div {
        justify-content: center;
    }
    div.header div img {
        max-width: 90vw;
        
    }
    div.header p {
        font-size: 10vw;
        width: 95vw;
        padding-left: 2.5vw;
    }
}


div.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* div.gallery img{
    height: 100%;
} */
div.footer {
    display: flex;
    flex-flow: row wrap;
}

div.footer .firstRow {
    width: 100vw;
    font-size: 2vw;
	display: flex;
	// grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	// grid-auto-rows: 1fr;
	// place-items: center;
	justify-content: center;
	align-items: center;
	margin-left: 100px;
	margin-right: 100px;

	img {
		width: 128px;
		max-width: 20vw;
		margin-bottom: 5px;
	}


	a.iconItem {
		display: flex;
		flex-flow: column;
		align-items: center;
		white-space: nowrap;
		margin-left: 25px;
		margin-right: 25px;
	}
}

div.footer .secondRow {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    font-size: 2vw;
    justify-content: space-between;

	div:nth-child(2) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

}

div.footer .secondRow .map {
    width: 45vw;
    height: 100%;
    /* overflow: hidden; */
}

div.footer a {
    color: #D0B76B;
}
@media only screen and (max-width: 1000px) {
    div.footer .secondRow {
        font-size: 4vw;
    }

}
@media only screen and (max-width: 800px) {
    div.footer .firstRow {
        width: 100%;
        font-size: 4vw;
        height: 15%;
        text-align: center;
		margin-left: 15px;
		margin-right: 15px;
		margin-top: 25px;
		margin-bottom: 25px;
    }

    div.footer .secondRow {
        width: 100%;
        height: 80%;

        display: flex;
        flex-flow: row wrap;
        font-size: 4vw;
    }

    div.footer .secondRow .map {
        width: 100vw;
        height: 35vh;
        /* overflow: hidden; */
    }
}